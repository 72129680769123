<template>
	<Menu />
	<div class="py-48 px-4">
		<div class="flex justify-center text-center font-bold text-gray-500 text-2xl">
			<EmojiSadIcon class="w-12 h-12" />
		</div>
		<div class="flex justify-center">
			<div class="mt-4 text-center  text-gray-300 text-sm md:text-lg max-w-md">
				La página que buscas no existe. <br> Puedes ingresar en el menú a cualquier sección.
			</div>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/core/Menu'
import { EmojiSadIcon } from '@heroicons/vue/outline'

export default {
	components: {
		Menu,
		EmojiSadIcon
	},
	setup() {
		
	},
}
</script>